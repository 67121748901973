import {bindable, customElement, inject} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {I18N} from "aurelia-i18n";
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-accounting-pal-statement')
export class PalStatement {

    client;
    dialogService;
    flash;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(print) {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;

        let workflowId = print ? 'accounting/generate-pal-print' : 'accounting/generate-pal';

        return this.workflowService.trigger(workflowId, null, data).then(data => {

            this.data = data[0].palStatement;
            this._isSubmitting = false;

            if (data[0].url) {
                window.location.href = data[0].url;
            }

            return data;
        });
    }
}
