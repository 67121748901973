import {customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import "./balance-list.less";

@inject(Client, DialogService, FlashService, WorkflowService)
@customElement('sio-accounting-balance-list')
export class BalanceList {

    client;
    dialogService;
    flash;
    form;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
        this.sumArr = {
            "currency": "EUR",
            "values": {
                "transfer": 0,
                "monthDr": 0,
                "monthCr": 0,
                "yearDr": 0,
                "yearCr": 0,
                "balance": 0
            }
        };
    }

    submit(type) {
        const data = this.form.formService.getValue();
        data.organization = {id: data.organization.id, modelId: data.organization.modelId};
        data.period = {id: data.period.id, modelId: data.period.modelId};

        this._isSubmitting = true;
        this.data = null;
        return this.workflowService
            .trigger(`accounting/generate-balance-list${ type ? "-" + type : ""}`, null, data)
            .then(data => {

                this.data = data[0].list;
                this._isSubmitting = false;

                if (data[0].url) {
                    window.location.href = data[0].url;
                }
        
                let collectionData = []
                for (const [key,value] of Object.entries(this.data)) {
                    for (const dataproc of Object.entries(value.sum)) {
                        collectionData.push(dataproc);
                    }
                }                
                collectionData.forEach(data => {
                    Object.entries(data[1]).forEach(([key, value]) => {
                        this.sumArr.values[key] += value;
                    });
                });                
                return data;
            });
    }
}
