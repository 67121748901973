import {createSelector, createSlice} from "@reduxjs/toolkit"
import {Container} from "aurelia-dependency-injection"
import {FlashService} from "../../flash/flash-service"
import {setContext} from "./entries-slice"
import {
    accountingBaseDataLoaded,
    accountingBookingLoaded,
    accountingBookingLoading,
    accountingBookingSaved,
    accountingBookingSaveError,
    accountingBookingSaving
} from "./accounting-api"

/**
 * @type {FlashService}
 */
const flash = Container.instance.get(FlashService)

function checkCanSave(state) {
    state.canRemovePart = 2 < state.booking.parts.length
    state.canSave = !!(state.booking.bookDate && state.booking.receiptNumber && state.booking.subject)
    state.crSum = state.drSum = 0

    for (const {dr, cr, account} of state.booking.parts) {
        if (!account || (dr && cr)) {
            state.canSave = false
        }

        state.drSum += dr ?? 0
        state.crSum += cr ?? 0
    }

    if (state.drSum !== state.crSum) {
        state.canSave = false
    }
}

function calculateSimpleTax(state) {
    const amount = state.booking.parts[0].dr

    if (state.tax) {
        const percentage = state.taxAccounts[state.organization][state.tax].percentage;
        const untaxedAmount = Math.round(amount / (1 + percentage))

        state.booking.parts[1].cr = untaxedAmount
        state.booking.parts[2] = {
            account: {id: state.tax, modelId: "accounting/ledger-account"},
            cr: amount - untaxedAmount
        }
    } else {
        state.booking.parts[1].cr = amount
        state.booking.parts = state.booking.parts.slice(0, 2)
    }

    checkCanSave(state)
}

function setPartAccount(state, index, account) {
    state.booking.parts[index].account = account
    checkCanSave(state)
}

function setPartAmount(state, {payload: {index, cr, dr}}) {
    state.booking.parts[index].cr = cr
    state.booking.parts[index].dr = dr

    if (0 === index && state.booking.parts[1].percentage) {
        calculatePercentages(state, 1)
    } else {
        for (const part of state.booking.parts) {
            part.percentage = null
        }

        checkCanSave(state)
    }
}

function calculatePercentages(state, changedIndex) {
    const parts = state.booking.parts.slice(0, changedIndex + 1)
    const [base, useCr] = parts[0].dr ? [parts[0].dr, true] : parts[0].cr ? [parts[0].cr, false]: [0, false]

    if (!base) {
        return
    }

    let rest = base, restPercentage = 1.0

    for (let i = 1; i < parts.length; ++i) {
        const percentage = parts[i].percentage ?? 0.0
        const amount = Math.round(base * percentage)
        rest -= amount
        restPercentage -= percentage

        if (useCr) {
            parts[i].cr = amount
            parts[i].dr = null
        } else {
            parts[i].dr = amount
            parts[i].cr = null
        }
    }

    if (rest || restPercentage) {
        parts.push({
            [useCr ? "cr" : "dr"]: rest,
            percentage: restPercentage
        })
    }

    state.booking.parts = parts

    checkCanSave(state)
}

const editorSlice = createSlice(({
    name: "accounting/editor",
    initialState: {
        open: false,
        loading: false,
        errors: null,
        tab: "simple",
        taxAccounts: {},
        tax: null,
        drSum: 0,
        crSum: 0,
        canSave: false,
        canRemovePart: false,
    },
    reducers: {
        setTab: {
            prepare: tab => ({payload: tab}),
            reducer(state, {payload: tab}) {
                state.tab = tab
            }
        },
        closeEditor(state) {
            state.loading = false
            state.open = false
            state.id = undefined
            state.errors = undefined
        },
        setBooking: {
            prepare: booking => ({payload: booking}),
            reducer(state, {payload: booking}) {
                state.booking = booking
                checkCanSave(state)
            }
        },
        newBooking: {
            prepare: ({organization, period, stack}) => ({payload: {organization, period, stack}}),
            reducer(state, {payload: {period, stack, organization}}) {
                state.open = true
                state.loading = false
                state.id = undefined
                state.errors = undefined
                state.canSave = false
                state.canRemovePart = false
                state.drSum = 0
                state.crSum = 0
                state.booking = {
                    parts: [{}, {}],
                    bookDate:  null,//(new Date).toISOString(),
                    receiptDate: null,//(new Date).toISOString(),
                    receiptNumber: "",
                    period: period ? {id: period, modelId: "accounting/period"} : null,
                    organization: {id: organization, modelId: "organization/organization"},
                    subject: "",
                    costObject: null,
                    reference: null,
                    stack: stack ? {id: stack, modelId: "accounting/stack"} : null
                }
            }
        },

        // simple editor

        changeAmount: {
            prepare: amount => ({payload: amount}),
            reducer(state, {payload: amount}) {
                state.booking.parts[0].dr = amount
                calculateSimpleTax(state)
            }
        },
        changeTax: {
            prepare: taxAccount => ({payload: {taxAccount}}),
            reducer(state, {payload: {taxAccount}}) {
                if ("" === taxAccount || !taxAccount) {
                    state.booking.parts = state.booking.parts.slice(0, 2)
                    state.tax = null
                    state.booking.tax = .0
                } else {
                    state.tax = taxAccount

                    if (state.booking.parts.length < 4) {
                        calculateSimpleTax(state)
                    }
                }
            }
        },
        changeAccount: {
            prepare: account => ({payload: account}),
            reducer: (state, {payload: account}) => setPartAccount(state, 0, account),
        },
        changeContraAccount: {
            prepare: account => ({payload: account}),
            reducer: (state, {payload: account}) => setPartAccount(state, 1, account),
        },

        // complex editor

        addPart(state) {
            state.booking.parts.push({})
            state.canRemovePart = true
            state.canSave = false
        },
        removePart: {
            prepare: index => ({payload: index}),
            reducer(state, {payload: index}) {
                const {dr, cr} = state.booking.parts[index]
                state.booking.parts = state.booking.parts.toSpliced(index, 1)
                state.drSum -= dr
                state.crSum -= cr
                checkCanSave(state)
            }
        },
        changePartAccount: {
            prepare: (index, account) => ({payload: {index, account}}),
            reducer: (state, {payload: {index, account}}) => setPartAccount(state, index, account),
        },
        changePartDr: {
            prepare: (index, dr) => ({payload: {index, dr}}),
            reducer: setPartAmount,
        },
        changePartCr: {
            prepare: (index, cr) => ({payload: {index, cr}}),
            reducer: setPartAmount,
        },
        changePartPercentage: {
            prepare: (index, percentage) => ({payload: {index, percentage}}),
            reducer(state, {payload: {index, percentage}}) {
                state.booking.parts[index].percentage = percentage
                calculatePercentages(state, index)
            }
        },
        splitTax: {
            prepare: (index, account) => ({payload: {index, account}}),
            reducer(state, {payload: {index, account}}) {
                const {percentage} = state.taxAccounts[state.organization][account]
                const {dr, cr} = state.booking.parts[index]
                const tax = Math.round((dr ?? cr) * percentage)
                account = {id: account, modelId: "accounting/ledger-account"}

                if (dr) {
                    state.booking.parts[index].dr -= tax
                    state.booking.parts.push({account, dr: tax})
                } else {
                    state.booking.parts[index].cr -= tax
                    state.booking.parts.push({account, cr: tax})
                }

                checkCanSave(state)
            }
        }
    },
    extraReducers: builder => builder
        .addCase(setContext, (state, {payload: {organization}}) => {
            state.organization = organization
        })
        .addMatcher(accountingBaseDataLoaded, (state, {payload: {organizations}}) => {
            state.taxAccounts = Object.fromEntries(
                Object.entries(organizations).map(([id, {taxAccounts}]) => [
                    id,
                    taxAccounts
                ])
            )
        })
        .addMatcher(accountingBookingLoading, state => {
            state.open = true
            state.loading = true
        })
        .addMatcher(accountingBookingLoaded, (state, {
            payload: {
                id, parts, bookDate, receiptDate, receiptNumber,
                subject, costObject, reference
            }
        }) => {
            state.open = true
            state.loading = false
            state.id = id
            state.tab = 2 === parts.length ? "simple" : "complex"
            state.errors = undefined
            state.canSave = true

            if (parts[0].hasOwnProperty("cr")  && 2 === parts.length) {
                if(parts[1].hasOwnProperty("dr")  && parts[1].dr != undefined){
                    [parts[0], parts[1]] = [parts[1], parts[0]];
                }
            }
            state.booking = {
                parts, bookDate, receiptDate, receiptNumber,
                subject, costObject, reference
            }

            checkCanSave(state)
        })
        .addMatcher(accountingBookingSaving, state => {
            state.errors = undefined
        })
        .addMatcher(accountingBookingSaved, state => {
            state.loading = false
            state.open = false
            flash.success("Erfolgreich gespeichert")
        })
        .addMatcher(accountingBookingSaveError, (state, {payload: {data, status}}) => {
            if (500 === status) {
                flash.error(data?.localizedMessage ?? data?.message ?? "Ein Fehler ist aufgetreten")
                state.errors = undefined
            } else {
                state.errors = data?.errors?.children ?? {}
                console.error(data?.errors?.children)
            }
        })
}))

const editor = editorSlice.reducer
const selectEditor = createSelector(state => state?.accounting?.editor ?? {}, editor => editor)
const selectOrganization = createSelector(selectEditor, ({organization}) => organization)
const selectTaxAccounts = createSelector(selectEditor, ({taxAccounts}) => taxAccounts)

export default editor
export const {
    setTab,
    closeEditor,
    setBooking,
    newBooking,
    changeAmount,
    changeTax,
    changeAccount,
    changeContraAccount,
    addPart,
    removePart,
    changePartAccount,
    changePartDr,
    changePartCr,
    changePartPercentage,
    splitTax,
} = editorSlice.actions

export const selectEditorDrawer = createSelector(selectEditor, ({open, loading}) => [open, loading])
export const selectBooking = createSelector(selectEditor, ({booking}) => booking)
export const selectBookingId = createSelector(selectEditor, ({id}) => id)
export const selectErrors = createSelector(selectEditor, ({errors}) => errors)
export const selectTab = createSelector(selectEditor, ({tab}) => tab)

export const selectTaxChoices = createSelector(
    [selectOrganization, selectTaxAccounts],
    (organization, taxAccounts) => [
        {value: "", label: "0 %"},
        ...Object.entries(taxAccounts[organization]).map(
            ([value, {label}]) => ({value, label})
        )
    ]
)

export const selectTaxAccount = createSelector(selectEditor, ({tax}) => tax)
export const selectPartCount = createSelector(selectBooking, ({parts}) => parts.length)
export const selectCanRemovePart = createSelector(selectEditor, ({canRemovePart}) => canRemovePart)
export const selectCanSave = createSelector(selectEditor, ({canSave}) => canSave)
export const selectSums = createSelector(selectEditor, ({drSum, crSum}) => [drSum, crSum])
export const selectBookingPart = index => createSelector(selectBooking, ({parts}) => parts[index] ?? {})
