import {bindable, containerless, customElement, inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {ReportItemPositionsDialog} from "./report-item-positions-dialog";

@customElement('sio-accounting-report-item')
@containerless
@inject(DialogService)
export class PalStatement {
    @bindable item;
    @bindable level;
    @bindable detailTitle;
    @bindable showPrevious;

    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    open(positions) {
        this.dialogService
            .open({
                viewModel: ReportItemPositionsDialog,
                model: {
                    positions: positions,
                    title: this.detailTitle,
                    showPrevious: this.showPrevious
                }
            })
            .whenClosed(response => {
            });
    }
}
