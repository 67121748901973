import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import {Client} from '../../api/client';

@inject(DialogController)
export class BwaPositionsDialog {

    dialogController;
    client;

    participants;
    result;

    constructor(dialogController, client) {
        this.dialogController = dialogController;
        this.client = client;
    }

    activate(context) {
        this.positions = context.positions;
        this.title = context.title;
        this.headers = context.headers;
    }
}
