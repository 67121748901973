import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import {BwaPositionsDialog} from "./bwa-positions-dialog";

import "./bwa-statement.less";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService,
    Element
)
@customElement('sio-accounting-bwa-statement')
export class BwaStatement {

    client;
    dialogService;
    flash;
    form;

    @bindable() bwa;
    @bindable positions = null;

    async bind() {
        this.positions = null;
        this.title = 'BWA-Position';
        this.headers = null;
    }
    pdfConfig = {
        label: 'PDF-Download',
        buttonClass: 'btn btn-primary'
    }
    excelConfig = {
        label: 'Excel-Download',
        buttonClass: 'btn btn-primary'
    }
    submitConfig = {
        label: 'Anzeigen',
        buttonClass: 'btn btn-primary'
    }

    constructor(client, dialogService, flash, workflowService,element) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
        this.container = element;
        this.modalOpen = false;
    }


    submit(type) {
        this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = true;
        this.data = null;
        this.dummyDataForTable = [];
        return this.workflowService
            .trigger(`accounting/generate-bwa${type ? "-" + type : ""}`, [this.bwa], this.form.formService.getValue())
            .then(data => {
                this.data = data[0].bwaStatement;

                if (data[0].url) {
                    window.location.href = data[0].url;
                }
                
                 for (const [key, value] of Object.entries(this.data.items)) {
                    console.log(`${key}: ${value}`);
                    this.dummyDataForTable.push({...value,mainId:key})
                    value.positions?.map(position =>{
                        // value.title.replace(/[.,/\s]/g, '')
                        this.dummyDataForTable.push({...position,id:key})
                    })
                }
                
                return dummyDataForTable;
            }).finally(() => {
                this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = false;
            })
    }

    open(title,btnIndex) {
        let id = title.mainId
        const element = document.getElementById(id);
        var elms = document.querySelectorAll(`[id='${id}']`);
        var theButton = document.getElementById(btnIndex);
        if (theButton.getAttribute("aria-expanded") == "false"){
           
            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('visible');
                elms[i].classList.remove('hiderow');

            }
            theButton.setAttribute("aria-expanded", "true");
        }else{
            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('hiderow');
                elms[i].classList.remove('visible');
            }
            theButton.setAttribute("aria-expanded", "false");
        }
    }
    closeModal(positions){
        this.modalOpen = false;
        this.positions = null;
        this.title = 'BWA-Position';
        this.headers = this.data.headers;
    }
}
